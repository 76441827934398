<template>
    <!-- showGiveTipsMoreInfo -->
    <div id="topdiv" class="modal-overlay-canscroll" style="position: absolute; top: 10px; width: 100%; outline:0.0em solid pink; ">
        <div class="modal" style="outline:0.0em solid pink; padding-top: 5vh; padding-bottom: 5vh; "> 

        <table width=100% border=0 style="margin: auto; outline:0.0em solid blue; padding-left: 2.5vh; padding-right: 2.5vh; ">
            <tr>
                <td>
                    <table width="100%" border=0>
                        <tr>
                            <td width="30%">
                                <div class=divl><button class="text-only-button cancel" @click="this.toggleAll" style="display: block; line-height: 15px;">{{ toggleButtonText }}</button></div>
                            </td>
                            <td width="40%">
                            </td>
                            <td width="30%">
                            <div class=divr>
                            
                                <button @click="this.goBack();" class="text-only-button cancel"> <img class="tulogo" style="height: 4vh;" alt="close" src="../assets/greycross.png"></button>
                            </div>
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr>
                <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">              
                    <div class="actionbtn-div" style="padding-top: 1vh;">
                        <button @click="this.showFeeInfo=!this.showFeeInfo" class="actionbtn" style="width: 100%; height: 6.5vh; font-size: 2vh; " >{{ this.isThereAFee }}
                        </button>
                    </div>
                    <div v-show="showFeeInfo" class="actionbtn-div notesInfoText" style="">
                        Fees for using thankU are charged to the person you are tipping by deducting fees from tips - unless you choose to add them onto your tip so that the person you are tipping gets the full tip. You are given the option to do this before you confirm payment.
                    </div>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">              
                    <div class="actionbtn-div" style="padding-top: 1vh;">
                        <button @click="this.showSecureInfo=!this.showSecureInfo" class="actionbtn" style="width: 100%; height: 6.5vh; font-size: 2vh; " >{{ this.isTippingSecure }}
                        </button>
                    </div>
                    <div v-show="showSecureInfo" class="actionbtn-div notesInfoText" style="">
                        Yes - thankU's default payment methods are Apple Pay or Google Pay and thankU uses Stripe as its payment processor. Stripe processes about 15% of all transactions on the internet, and likely rather more in payment-enabled phone apps. Stripe processes payments for millions of business including Amazon, Deliveroo, Booking.com and ASOS - <a href="https://stripe.com/en-gb/customers" target=blank>see some Stripe customers</a> <p></p>
                        If you don't use Apple Pay or Google Pay, tipping with your card is still just as secure - because it uses the same kind of process, converting your card details into a random string of letters and numbers, called a digital secure token.      
                    </div>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">              
                    <div class="actionbtn-div" style="padding-top: 1vh;">
                        <button @click="this.showWhoTipped=!this.showWhoTipped" class="actionbtn" style="width: 100%; height: 6.5vh; font-size: 2vh; " >{{ this.willTheRecipientKnow }}
                        </button>
                    </div>
                    <div v-show="showWhoTipped" class="actionbtn-div notesInfoText" style="">
                        When you give your first tip with thankU, at the end of the process you will be asked if you would like to let the recipient know who tipped them. If you would, then you tap in e.g. your first name or whatever you wish, and from then on that's what anyone you tip will see - that is the only piece of information which is shared with them. For example, your email address is NEVER shared with them.<p></p>If you choose not to supply this info, then your recipient's tip receipt will simply say "name not given".
                    </div>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">              
                    <div class="actionbtn-div" style="padding-top: 1vh;">
                        <button @click="this.showCardNextTime=!this.showCardNextTime" class="actionbtn" style="width: 100%; height: 6.5vh; font-size: 2vh; " >{{ this.whatHappensNextTime }}
                        </button>
                    </div>
                    <div v-show="showCardNextTime" class="actionbtn-div notesInfoText" style="">
                        When you pay by card, there is a tick box marked "Save securely for next time". If you tick the box, thankU saves a secure digital token (not your card details) which means next time you tip, you don't need to enter your card details again.<p></p>If you do not tick the box, your secure digital token will not be saved and you will be required to enter the details again next time you use thankU.
                    </div>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">              
                    <div class="actionbtn-div" style="padding-top: 1vh;">
                        <button @click="this.showTipWithoutScanningOption=!this.showTipWithoutScanningOption;" class="actionbtn" style="width: 100%; height: 6.5vh; font-size: 2vh; " >{{ this.tipWithoutScanningOption }}
                        </button>
                    </div>
                    <div v-show="showTipWithoutScanningOption" class="actionbtn-div notesInfoText" style="">
                        Normally, you tip with thankU by scanning the thankU QR code badge at the place you want to tip, e.g. your hair salon. However, if you want to leave a tip after you have left, simply tap the 'Tip without scanning' button, find the business where you want to tip in the list displayed, then select the person at that business you want to tip, and tip them as normal. 
                    </div>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">              
                    <div class="actionbtn-div" style="padding-top: 1vh;">
                        <button @click="this.showGetReceipt=!this.showGetReceipt" class="actionbtn" style="width: 100%; height: 6.5vh; font-size: 2vh; " >{{ this.getReceipt }}
                        </button>
                    </div>
                    <div v-show="showGetReceipt" class="actionbtn-div notesInfoText" style="">
                        Yes you can - at the end of your first tip, you will be asked if you want a receipt - if you do, just provide your email address, and thankU will send you a receipt for that tip and all your future tips. <p></p> If you want, you can supply your email later through the account screen but you can only get receipts for tips made from the point in time when you supplied your email address, though full details will always be available in your Transaction History, which you can also have emailed to you at any time.
                    </div>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">              
                    <div class="actionbtn-div" style="padding-top: 1vh;">
                        <button @click="this.showGetTipHistory=!this.showGetTipHistory;" class="actionbtn" style="width: 100%; height: 6.5vh; font-size: 2vh; " >{{ this.tipHistory }}
                        </button>
                    </div>
                    <div v-show="showGetTipHistory" class="actionbtn-div notesInfoText" style="">
                        Yes you can - click on the account icon to go into your account page and select Transaction History from there. If you have supplied your email address we can also email you Transaction History reports. 
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <table width="100%" border=0>
                        <tr>
                            <td width="1%">
                               &nbsp;
                            </td>
                            <td width="98%" class="divc">
                                 <div class="divc" style="padding-top: 1.5vh">
                                     <a href="mailto:hello@thanku.app?subject=thankU enquiry from giving tips screen&amp;body=How can we help?" class="text-only-button cancel" >Email us your question</a>
                                     <!-- <button class="text-only-button cancel" @click="emailThankU" >Email us your question</button> -->
                                     </div>
                            </td>
                            <td width="1%">
                                <div class=divr>
                                </div>
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
        </table>       
      </div>
    </div>
</template>

<script>

import shared from '../shared.js';

export default {
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    data() {
        return {
            showFeeInfo: false,
            showSecureInfo: false,
            showWhoTipped: false,
            showCardNextTime: false,
            showTipWithoutScanningOption: false,
            showGetReceipt: false,
            showGetTipHistory: false,
            toggleButtonText: "Expand All",
            isThereAFee: "Is there a fee to use thankU?",
            isTippingSecure: "Is tipping with thankU secure?",
            willTheRecipientKnow: "Will the recipient know who tipped them?",
            whatHappensNextTime: "If I pay by card, what happens next time I tip?",
            tipWithoutScanningOption: "What is the 'tip without scanning' option?",
            getReceipt: "Can I get a payment receipt from thankU?",
            tipHistory: "Can I get a history of the tips I've given?"
        }
    },
    methods: {
        toggleAll(){

            // /console.log("toggling...");

            var status = false;

            if (this.toggleButtonText === "Expand All") {
                this.toggleButtonText = "Collapse All";
                status = true;
            } else {
                this.toggleButtonText = "Expand All";
                status = false;
            }

            this.showFeeInfo = status;
            this.showSecureInfo = status;
            this.showWhoTipped = status;
            this.showCardNextTime = status;
            this.showTipWithoutScanningOption = status;
            this.showGetReceipt = status;
            this.showGetTipHistory = status;
        },
        goBack(){
        // /console.log("we're off!");
            if (window.localStorage.getItem("nextScreen") !== null ){
                let returnJSON = { nextScreen: window.localStorage.getItem("nextScreen")};
                this.$emit('return-json', returnJSON);    
                window.localStorage.removeItem("nextScreen");
            } else {
                let returnJSON = { showHome: true }; 
                this.$emit('return-json', returnJSON);
            }
        },
        doSetMetas(){

            document.title = "All your questions answered on giving tips with thankU cashless tipping, used at top salons such as Larry King and Josh Wood Colour. ";
            document.getElementsByTagName('meta').namedItem('description').setAttribute('content',
            'All your questions answered on giving tips with thankU cashless tipping, used at top salons such as Larry King and Josh Wood Colour. Questions like: ' + this.isThereAFee + " " + this.isTippingSecure + " " + this.willTheRecipientKnow + " " + this.whatHappensNextTime + " " + this.tipWithoutScanningOption + " " + this.getReceipt + " " + this.tipHistory + " ") ;
            
        }  
        
    },
    created(){
        document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
        this.doSetMetas();
        shared.saveToUserPath(this.devEnv, "Give tips more info " + new Date().toISOString(), this.globalPushForwardInterval);
    },
}
</script>